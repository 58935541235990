const Cancel = () => {
  return (
    <section>
      <p>
        Forgot to add something to your cart? Shop around then come back to pay!
      </p>
    </section>
  );
};

export default Cancel;
